.product-badges {
    position: absolute;
    left: 25px;
    top: 25px;
    font-size: 12px;
    color: $white;
    text-align: center;
    text-transform: uppercase;

    @include mq($mobile-small-max) {
        left: 10px;
        top: 10px;
        font-size: 10px;
    }

    .product-badge-volume_discount,
    .product-badge-resource {
        padding: 8px;
        width: auto !important;
    }

    .product-badge-exclusive,
    .product-badge-volume_discount,
    .product-badge-resource {
        @include mq($mobile-small-max) {
            height: 50px;
            width: 50px;
            padding: 4px;
        }
    }

    .product-badge-new,
    .product-badge-sale,
    .product-badge-volume_discount .product-badge-resource,
    .product-badge-discount,
    .product-badge-exclusive {
        display: table-cell;
        vertical-align: middle;
        background-color: $black;
        border-radius: 50%;
        height: 75px;
        width: 75px;
        user-select: none;

        @include mq($mobile-small-max) {
            height: 50px;
            width: 50px;
        }
    }
}

.small-product-badges {
    margin-left: 10px;
    font-size: 12px;
    color: $white;
    text-align: center;
    text-transform: uppercase;

    @include mq($mobile-small-max) {
        margin-left: 10px;
        font-size: 10px;
    }

    .small-product-badge-new,
    .small-product-badge-sale,
    .small-product-badge-volume_discount,
    .small-product-badge-resource,
    .small-product-badge-discount,
    .small-product-badge-exclusive {
        display: table-cell;
        vertical-align: middle;
        background-color: $black;
        width: 100px;
        height: 30px;
        user-select: none;

        @include mq($mobile-small-max) {
            height: 30px;
            width: 100px;
        }
    }

    .small-product-badge-volume_discount,
    .small-product-resource {
        width: 126px;
        @include mq($mobile-small-max) {
            width: 100px;
        }
    }
}
