@import '../pages/page-include';
@import '../05-components/product-tile';

.tile-body-wrapper {
    text-align: center;
    padding: 20px 0;
}

.product-list__wrapper {
    padding-bottom: 20px;
}

.product-list__item {
    padding: 5px 0;
}
