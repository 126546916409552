/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.product-badges {
  position: absolute;
  left: 25px;
  top: 25px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 560px) {
  .product-badges {
    left: 10px;
    top: 10px;
    font-size: 10px;
  }
}
.product-badges .product-badge-volume_discount,
.product-badges .product-badge-resource {
  padding: 8px;
  width: auto !important;
}
@media only screen and (max-width: 560px) {
  .product-badges .product-badge-exclusive,
.product-badges .product-badge-volume_discount,
.product-badges .product-badge-resource {
    height: 50px;
    width: 50px;
    padding: 4px;
  }
}
.product-badges .product-badge-new,
.product-badges .product-badge-sale,
.product-badges .product-badge-volume_discount .product-badge-resource,
.product-badges .product-badge-discount,
.product-badges .product-badge-exclusive {
  display: table-cell;
  vertical-align: middle;
  background-color: #000;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  user-select: none;
}
@media only screen and (max-width: 560px) {
  .product-badges .product-badge-new,
.product-badges .product-badge-sale,
.product-badges .product-badge-volume_discount .product-badge-resource,
.product-badges .product-badge-discount,
.product-badges .product-badge-exclusive {
    height: 50px;
    width: 50px;
  }
}

.small-product-badges {
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 560px) {
  .small-product-badges {
    margin-left: 10px;
    font-size: 10px;
  }
}
.small-product-badges .small-product-badge-new,
.small-product-badges .small-product-badge-sale,
.small-product-badges .small-product-badge-volume_discount,
.small-product-badges .small-product-badge-resource,
.small-product-badges .small-product-badge-discount,
.small-product-badges .small-product-badge-exclusive {
  display: table-cell;
  vertical-align: middle;
  background-color: #000;
  width: 100px;
  height: 30px;
  user-select: none;
}
@media only screen and (max-width: 560px) {
  .small-product-badges .small-product-badge-new,
.small-product-badges .small-product-badge-sale,
.small-product-badges .small-product-badge-volume_discount,
.small-product-badges .small-product-badge-resource,
.small-product-badges .small-product-badge-discount,
.small-product-badges .small-product-badge-exclusive {
    height: 30px;
    width: 100px;
  }
}
.small-product-badges .small-product-badge-volume_discount,
.small-product-badges .small-product-resource {
  width: 126px;
}
@media only screen and (max-width: 560px) {
  .small-product-badges .small-product-badge-volume_discount,
.small-product-badges .small-product-resource {
    width: 100px;
  }
}

.add-to-cart-wrapper .btn-plp-cart {
  font-size: 10px;
  min-width: 120px;
}
.add-to-cart-wrapper:not(.add-to-cart-wrapper--tablet-mobile) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
  opacity: 0;
  z-index: -5;
  transition: 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.add-to-cart-wrapper:not(.add-to-cart-wrapper--tablet-mobile) .btn-plp-cart {
  border: 0;
}
.add-to-cart-wrapper.add-to-cart-wrapper--tablet-mobile {
  display: none;
}
.add-to-cart-wrapper.add-to-cart-wrapper--set {
  margin-top: auto;
}
.add-to-cart-wrapper .animated-btn-text {
  transform: translateY(100%);
  transition: 0.5s cubic-bezier(0.4, 0.8, 0.5, 1);
}
@media only screen and (max-width: 1366px) {
  .add-to-cart-wrapper {
    display: none;
  }
  .add-to-cart-wrapper.add-to-cart-wrapper--tablet-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .add-to-cart-wrapper .animated-btn-text {
    transform: translateY(0);
  }
}

.product-tile-container {
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .product-tile-container:hover .add-to-cart-wrapper {
    opacity: 1;
    z-index: 5;
    bottom: 10px;
  }
  .product-tile-container:hover .add-to-cart-wrapper .animated-btn-text {
    transform: translateY(0);
  }
}
.product-tile-container .image-wrapper {
  display: block;
  line-height: 0;
  position: relative;
  padding-bottom: 106%;
}
.product-tile-container .image-tile {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.product-tile-container.product-tile-container--set {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .product-tile-container.product-tile-container--set {
    height: 100%;
  }
}

.tile-body-wrapper {
  padding: 16px 0;
  font-size: 14px;
  text-align: center;
}
.tile-body-wrapper .price {
  padding-top: 5px;
}
.tile-body-wrapper .reduced-value {
  font-family: "SkandinaviskBold", sans-serif;
}
.tile-body-wrapper .pdp-link {
  display: block;
  padding-top: 5px;
}
.tile-body-wrapper.tile-body-wrapper--set {
  padding: 16px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.tile-body-wrapper .product-badges {
  top: 13px;
  left: 13px;
}
@media only screen and (min-width: 1200px) {
  .tile-body-wrapper .product-badges {
    top: 26px;
    left: 26px;
  }
}
.tile-body-wrapper .product-badges span {
  border-radius: 0;
  height: 26px;
}

.strike-through {
  text-decoration: line-through;
  opacity: 0.5;
  font-family: "Skandinavisk", sans-serif;
}

.tile-body-wrapper {
  text-align: center;
  padding: 20px 0;
}

.product-list__wrapper {
  padding-bottom: 20px;
}

.product-list__item {
  padding: 5px 0;
}