@import './product-badges';

$plp-badge-height: 26px;

.add-to-cart-wrapper {
    .btn-plp-cart {
        font-size: 10px;
        min-width: 120px;
    }

    &:not(.add-to-cart-wrapper--tablet-mobile) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
        opacity: 0;
        z-index: -5;
        transition: 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);

        .btn-plp-cart {
            border: 0;
        }
    }

    &.add-to-cart-wrapper--tablet-mobile {
        display: none;
    }

    &.add-to-cart-wrapper--set {
        margin-top: auto;
    }

    .animated-btn-text {
        transform: translateY(100%);
        transition: 0.5s cubic-bezier(0.4, 0.8, 0.5, 1);
    }

    @include mq($dt-med) {
        display: none;

        &.add-to-cart-wrapper--tablet-mobile {
            display: block;
            margin-bottom: 10px;
        }

        .animated-btn-text {
            transform: translateY(0);
        }
    }
}

.product-tile-container {
    position: relative;

    &:hover {
        @include mq($not-mobile-tablet) {
            .add-to-cart-wrapper {
                opacity: 1;
                z-index: 5;
                bottom: 10px;

                .animated-btn-text {
                    transform: translateY(0);
                }
            }
        }
    }

    .image-wrapper {
        display: block;
        line-height: 0;
        position: relative;
        padding-bottom: 106%;
    }

    .image-tile {
        @include image-aspect-ratio;
    }

    &.product-tile-container--set {
        @include mq($not-mobile) {
            height: 100%;
        }

        display: flex;
        flex-direction: column;
    }
}

//Price wrapper
.tile-body-wrapper {
    padding: $padding-s 0;
    font-size: 14px;
    text-align: center;

    .price {
        padding-top: 5px;
    }

    .reduced-value {
        font-family: $font-primary-bold;
    }

    .pdp-link {
        display: block;
        padding-top: 5px;
    }

    &.tile-body-wrapper--set {
        padding: $padding-s $padding-m;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .product-badges {
        top: $plp-badge-height / 2;
        left: $plp-badge-height / 2;
        @include mq($not-mobile-tablet) {
            top: $plp-badge-height;
            left: $plp-badge-height;
        }

        span {
            border-radius: 0;
            height: $plp-badge-height;
        }
    }
}

.strike-through {
    text-decoration: line-through;
    opacity: 0.5;
    font-family: $font-primary;
}
